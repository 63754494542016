<template>
  <div>
    <video ref="video" controls autoplay class="video"></video>
  </div>
</template>

<script>
  import Hls from "hls.js";
  import {Base64} from "js-base64";

  export default {
    name: "VideoPlayer",
    mounted() {
      this.getVideoFn();
    },
    methods: {
      getVideoFn() {
        let token = this.$route.query.token;
        if (token) {
          let decodeToken = Base64.decode(token);
          let decodeTokenArr = decodeToken.split("&");

          let code = decodeTokenArr[0];
          let expire = decodeTokenArr[1];

          var currentDate = new Date();
          var specifiedDate = new Date(expire);
          var isBefore = currentDate.getTime() < specifiedDate.getTime();
          if (isBefore) {

            this.$api.getDataT("elevatorLcds/getHlsUrl/" + code, null, 100).then(res => {
              if (res.data.status == 0) {
                alert(res.data.err);
              } else {
                const video = this.$refs.video;
                if (Hls.isSupported()) {
                  const hls = new Hls();

                  hls.loadSource(res.data.data);
                  hls.attachMedia(video);
                  hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.play();
                  });

                  // 禁用画中画
                  // video.addEventListener("enterpictureinpicture", function(event) {
                  //   event.preventDefault();
                  //   alert("hello");
                  // });
                  //
                  // video.addEventListener("leavepictureinpicture", function(event) {
                  //   event.preventDefault();
                  //   alert("world");
                  // });
                } else if (video) {
                  video.src = res.data.data;
                }

                // // 监听video元素的相关事件
                // video.addEventListener("enterpictureinpicture", function(event) {
                //   event.stopPropagation();
                //   console.log("Enter Picture in Picture");
                // });
                //
                // video.addEventListener("leavepictureinpicture", function(event) {
                //   event.stopPropagation();
                //   console.log("Leave Picture in Picture");
                // });
              }
            }).catch(() => {

            });
          } else {
            alert("token已经过期请重新申请");
          }
        }
      },
    },
    beforeDestroy() {
      if (this.hls) {
        this.hls.destroy();
      }
    },
  };
</script>

<style>
.video {
  width: 100%;
  height: auto;
}
</style>
